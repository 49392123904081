.changelog {
	margin-top: 0;
}

ul.labeled {
	font-size: 100%;
}

ul.labeled li {
	list-style: none;
	margin: 0.66em 0 0.66em 1.95em;
	font-size: 100%;
}

ul.labeled li::marker {
	font-size: 93%;
	letter-spacing: 0.03em;
}
