.flagContainer {
	display: block;
	left: -16px;
	margin: 30px 0;
	overflow: hidden;
	position: relative;
	width: 109%;
}

.flag {
	max-width: 1900px;
	min-width: 1570px;
	position: relative;
}

.flagStack {
	display: none;
}

@media only screen and (max-width: 550px) {
	.flagStack {
		display: block;
		position: relative;
		left: -8px;
		width: calc(460px + (785 - 460) * ((100vw - 300px) / (550 - 300)));
		min-width: 460px;
		margin: 10px 0;
	}

	.flag {
		display: none;
	}
}
